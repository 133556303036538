import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import CardsComponent from '../components/CardsComponent';
import FAProfileChartComponent from '../components/FAProfileChartComponent';
import NextStepComponent from '../components/NextStepComponent';
import axiosHelper from '../helpers/axiosHelper';
import { Visit } from '../models/Visit';
import './Home.css';

const Home: React.FC<RouteComponentProps> = ({ match }:any) => {
  const [visit, setVisit] = useState<Visit>();
  useEffect(() => {
    console.log(match);
    if (match.params.visitid) {
      axiosHelper.getVisitContents(match.params.visitid).then((value) => {
        setVisit(value.data)
      });
    }
    return () => {

    }
  }, [])

  return (<IonContent className='myStepComponent'>
    <div className='stpContent'>
    {visit && <>
      <FAProfileChartComponent visit={visit} showClaim={true} showDetails={true} showOthersInterests={true}/>
      <div className='myStepContent'>
        <NextStepComponent visit={visit} />
        <CardsComponent visit={visit} />
      </div>
    </>}
    </div>
  </IonContent>
  );
};

export default Home;
