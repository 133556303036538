import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axiosHelper from '../helpers/axiosHelper';
import { NexxtStepContent } from '../models/NexxtStepContent';
import ReactMarkdown from 'react-markdown';
import './MyStepComponent.scss';
import { IonAccordion, IonAccordionGroup } from '@ionic/react';
import { Visit } from '../models/Visit';

interface Props {
  visit: Visit;
}

const NextStepComponent: React.FC<Props> = ({ visit }) => {
  const { t } = useTranslation();
  const [nsContents, setNSContents] = useState<NexxtStepContent[]>();
  const [selectedContent, setSelectedContent] = useState<NexxtStepContent>();
  const [showUnlockedContents, setShowUnlockedContents] = useState(true);
  const [buttonsVisible, setButtonsVisible] = useState(false);

  useEffect(() => {
    // axiosHelper.getVisitContents(visit!.id).then((response) => {
    //   console.log('VISIT ========== ' + visit!.id);
    //   console.log(visit);
    //   setNSContents(response.data.faNexxtSteps);
    //   setButtonsVisible(true);
    // });

    return () => { };
  }, []);

  const openWebsite = (myUrl: string) => {
    if (!myUrl.startsWith('http')) {
      myUrl = 'https://' + myUrl;
    }
    // Browser.open({ url: myUrl });
    window.open(myUrl);
  };

  const toggleItem = (item: NexxtStepContent) => {
    if (selectedContent) {
      if (selectedContent === item) {
        setSelectedContent(undefined);
        document.getElementById('accordion_icon_' + item.id)!.classList.remove('open');
      } else {
        document.getElementById('accordion_icon_' + selectedContent.id)!.classList.remove('open');
        setSelectedContent(item);
        document.getElementById('accordion_icon_' + item.id)!.classList.add('open');
      }
    } else {
      setSelectedContent(item);
      document.getElementById('accordion_icon_' + item.id)!.classList.add('open');
    }
  };


  return (
    <div className='nexxtStepInfoSection'>
          <div className='title'>
          <span>Next Step</span>
          {/* <span>STEP 08</span> */}
        </div>
        <IonAccordionGroup>

        {visit.faNexxtSteps?.map((item, index) => (
          <IonAccordion key={index} className='nexxtStepItem' style={{ backgroundColor: item.bgColor, color: item.fgColor }} value={item.localizedTitle}>
            <div
              className='nexxtStepItemHeader'
              slot='header'
              onClick={() => {
                toggleItem(item);
              }}>
              <span>{item.localizedTitle}</span>
              <span id={'accordion_icon_' + item.id} className='icon'>
                <svg width='12' height='18' viewBox='0 0 12 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path d='M1.52014 17.5L11.9991 10.612V7.082L1.52014 0.196991L0.307129 2.92999L9.31213 8.849L0.307129 14.765L1.52014 17.5Z' fill={item.fgColor} />
                </svg>
              </span>
            </div>
            <div slot='content' className='nexxtStepItemContent'>
              <div className='nexxtStepItemDescription'>
                <ReactMarkdown children={item.localizedDescription} />
              </div>
              {item.localizedLinks.length > 0 && (
                <>
                  <div className={`nexxtStepItemLinks ${showUnlockedContents ? '' : 'disabled'}`}>
                    {item.localizedLinks?.map((link, linkIndex) => (
                      <div className='nexxtStepItemLink' key={linkIndex} onClick={() => openWebsite(link)}>
                        <div className='icon'>
                          <svg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <g opacity='1'>
                              <path d='M11.615 12.195V0.772034H13.769V12.195L11.615 14.349H0.182007V12.195H11.615Z' fill={item.fgColor} />
                              <path d='M-2.09401e-05 2.29949L11.6249 14.03L13.292 12.378L1.66704 0.647431L-2.09401e-05 2.29949Z' fill={item.fgColor} />
                            </g>
                          </svg>
                        </div>
                        <div className='link'>{link}</div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </IonAccordion>
        ))}
    </IonAccordionGroup>
    </div>
  );
};

export default NextStepComponent;
