import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import languageEN from './assets/i18n/en.json';
import languageIT from './assets/i18n/it.json';
i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(initReactI18next)
  // pass the i18n instance to react-i18next.
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'it',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      'it': { translation: languageIT },
      'en': { translation: languageEN },
    }
  }
);
//https://react.i18next.com/legacy-v9/step-by-step-guide
export default i18n;
