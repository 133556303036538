import axios from 'axios';
import { CherryPickContent } from '../models/CherryPickContent';
import { InterestStat } from '../models/InterestStat';
import { JobContent } from '../models/JobContent';
import { Visit } from '../models/Visit';
import Constants from './constants';


class AxiosHelper {
  visitID?: string;


  constructor() {
    console.log('AXIOS helper constructor');
  }



  setLang(lang: string) {
    axios.defaults.headers.common["lang"] = lang;

  }
  getVisitContents(visitid: string) {
    let vid;
    if (visitid == null) {
      vid = this.visitID;
    } else {
      vid = visitid;
    }
    console.log('GETTING VISIT ' + vid);
    return axios.get<Visit>(`${Constants.BASE_URL}/Visit/${vid}`)
  }
  getCherryPickContent(id: number) {
    return axios.get<CherryPickContent>(`${Constants.BASE_URL}/FACherryPick/${id}`)
  }

  getJobContent(id: number) {
    return axios.get<JobContent>(`${Constants.BASE_URL}/FAJob/${id}`)
  }

  setDeviceToken(token: string) {
    axios.defaults.headers.common["deviceToken"] = token;
  }
  
  getInterestsStats() {
    return axios.get<InterestStat[]>(`${Constants.BASE_URL}/InterestStat`)
  }

}

export default new AxiosHelper();
