import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import axiosHelper from '..//helpers/axiosHelper';
import { CherryPickContent } from '../models/CherryPickContent';
import { JobContent } from '../models/JobContent';
import { Visit } from '../models/Visit';

import './MyStepComponent.scss';

interface Props {
  visit: Visit;
}

const CardsComponent: React.FC<Props> = ({ visit }) => {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState('00');
  const [currentCP, setCurrentCP] = useState<CherryPickContent>();
  const [currentJob, setCurrentJob] = useState<JobContent>();
  const cpIndex = useRef('');
  const jIndex = useRef('');

  const openWebsite = (myUrl: string) => {
    if (!myUrl.startsWith('http')) {
      myUrl = 'https://' + myUrl;
    }
    // Browser.open({ url: myUrl });
    console.log('URL ' + myUrl)
  };


  const handleCPSelection = (contentId: number, index: string) => {
    axiosHelper.getCherryPickContent(contentId).then((response) => {
      setCurrentCP(response.data);
    });
    setCurrentIndex(index);
    cpIndex.current = index;
    jIndex.current = '';
  };

  const handleJobSelection = (contentId: number, index: string) => {
    axiosHelper.getJobContent(contentId).then((response) => {
      setCurrentJob(response.data);
    });
    setCurrentIndex(index);
    jIndex.current = index;
    cpIndex.current = '';
  };

  const resetSelection = () => {
    console.log('RESET SELECTION');
    cpIndex.current = '';
    setCurrentCP(undefined);
    jIndex.current = '';
    setCurrentJob(undefined);
  };

  const calculatedCherryPickIndex = (index: number) => {
    var myIndex = index + 1;
    return myIndex > 9 ? '' + myIndex : '0' + myIndex;
  };

  const calcShowMeCP = (index: number) => {
    const idx = calculatedCherryPickIndex(index);
    return idx === cpIndex.current;
  };

  const calculatedJobsndex = (index: number) => {
    var myIndex = index + 1;
    if (visit?.faCherryPicks) {
      myIndex += visit.faCherryPicks.length;
    }
    return myIndex > 9 ? '' + myIndex : '0' + myIndex;
  };

  const calcShowMeJ = (index: number) => {
    const idx = calculatedJobsndex(index);
    return idx === jIndex.current;
  };

  return (
    <div className='cardsSection'>
      <>
        {visit?.faCherryPicks?.map((item, index) => (
          <div className='cardBlock' key={'cherry_' + index}>
            {calcShowMeCP(index) ? <>
              {currentCP &&
                <div className='cpComponent' >
                  <div className='topPart' onClick={() => resetSelection()}>
                    <div className='cpBack' >
                      <span> </span>
                      <span>
                        <img src='./assets/img/arrow-right.svg' alt='' />
                      </span>

                    </div>

                    <div className='lens'>
                      <img src='/assets/img/magnifiyingGlass.svg' className='lensImg' alt='' />
                    </div>
                    <div className='cpTitle'>
                      {currentIndex}.<br />
                      {currentCP!.localizedGroup}
                    </div>
                    <div className='cpContent'>{currentCP!.localizedContentA}</div>
                  </div>
                  <div className='cpExtraContent cpExtraContentB'>
                    <p>{currentCP!.localizedContentB}</p>
                  </div>
                  <div className='cpExtraContent cpExtraContentC'>
                    <p>{currentCP!.localizedContentC}</p>
                  </div>
                  <div className='cpExtraContent cpExtraContentLinks'>
                    {currentCP?.localizedLinksArray?.map((link, linkIndex) => (
                      <div className='cpItemLink' key={linkIndex} onClick={() => openWebsite(link)}>
                        <div className='icon'>
                          <svg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <g opacity='1'>
                              <path d='M11.615 12.195V0.772034H13.769V12.195L11.615 14.349H0.182007V12.195H11.615Z' fill='#ffffff' />
                              <path d='M-2.09401e-05 2.29949L11.6249 14.03L13.292 12.378L1.66704 0.647431L-2.09401e-05 2.29949Z' fill='#ffffff' />
                            </g>
                          </svg>
                        </div>
                        <div className='link'>{link}</div>
                      </div>
                    ))}
                  </div>
                </div>

              }</>
              :
              <div className='cardItem' onClick={() => {
                handleCPSelection(item.id, calculatedCherryPickIndex(index));
              }}>
                <div className='cardInfo'>
                  <span>{calculatedCherryPickIndex(index)}.</span>
                  <span>
                    <img src='./assets/img/arrow-right.svg' alt='' />
                  </span>
                </div>
                <div className='cartTitle'>{item.localizedGroup}</div>
              </div>
            }
          </div>
        ))}
        {visit?.faJobs?.map((item, index) => (
          <div className='cardBlock' key={'job_' + index}>
            {calcShowMeJ(index) ? <>
              {currentJob && (
                <>
                  <div className='jobComponent'>
                    <div className='topPart' onClick={() => resetSelection()}>
                      <div className='jobBack' >
                        <span> </span>
                        <span>
                          <img src='./assets/img/arrow-right.svg' alt='' />
                        </span>
                      </div>
                      <div className='jobTitle'>
                        {currentIndex}.<br />
                        {currentJob?.name}
                      </div>
                      <div className='jobName'>{currentJob?.localizedFirstName}</div>
                      <div className='jobAvatar'>
                        <img src={`./assets/avatars/jobs_avatar_orig_${currentJob?.id}.jpg`} alt='avatar' />
                      </div>
                    </div>
                    <div className='jobContent'>
                      <div className='jobInfo'>
                        <span>{currentJob?.name}</span>
                        <br />
                        {currentJob?.localizedAge}
                        {currentJob?.localizedCity && ', ' + currentJob?.localizedCity}
                      </div>
                      <div className='jobDescription'>
                        <div className='section-title'>
                          <img src='./assets/img/jobs-icon1.svg' alt='' /> {t('JOBS_TTTLE')}
                        </div>
                        {currentJob?.localizedDescription}
                      </div>
                      <div className='jobSkills'>
                        <div className='section-title'>
                          <img src='./assets/img/jobs-icon2.svg' alt='' /> {t('JOBS_SKILLS')}
                        </div>
                        {currentJob?.localizedSkillsArray.map((aSkill, index) => (
                          <div key={index}>
                            {index < 8 ? '0' + (index + 1) : index + 1}. {aSkill}
                            <br />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              )}

            </> :

              <div className='cardItem' onClick={() => {
                handleJobSelection(item.id, calculatedJobsndex(index));
              }}>
                <div className='cardInfo'>
                  <span>{calculatedJobsndex(index)}.</span>
                  <span>
                    <img src='./assets/img/arrow-right.svg' alt='' />
                  </span>
                </div>
                <div className='cartTitle'>{item.name}</div>
              </div>
            }
          </div>
        ))}
      </>
    </div>
  );
};

export default CardsComponent;
