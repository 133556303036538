const Constants = {
    APP_VERSION: "1.0.24",
    MAGIC_POSITIONS: {
        //     "116": { x: 0.5, y: 0.5 },
        //     "115": { x: 0.05, y: 0.05 },
        //     "135": { x: 0.85, y: 0.05 },
        //     "81": { x: 0.5, y: 0.05 },
        "93": { x: 0.05, y: 0.95 },
        // "78": { x: 0.5, y: 0.95 },
        "14": { x: 0.95, y: 0.95 },
        "150": { x: 0.95, y: 0.5 },
        "151": { x: 0.5, y: 0.5 },
        "152": { x: 0.05, y: 0.5 }
    },
    MAX_CD_SCORE: 9,
    MAX_PF_SCORE: 36,
    CHERRY_PICKS_COUNT: 33,
    INTERACTION_HOTSPOT_SENSITIVITY: -50,
    RANDOM_BEACON_MODE: false,
    BUY_TICKET_WEBSITE: "https://tickets.steptothefuture.it/",
    // BASE_URL: "http://127.0.0.1/cgi-bin/WebObjects/STPBackend.woa/-56544/ra",
    // WS_BASE_URL: "http://127.0.0.1:4001",
    // WS_OPTIONS: {
    //     reconnection: true,
    //     reconnectionDelay: 1000,
    //     reconnectionDelayMax: 5000,
    //     reconnectionAttempts: Infinity,
    // },
    CHECK_URL: "https://app.steptothefuture.it/versioncheck.json",
    BASE_URL: "https://api.steptothefuture.it/api/v2",
    // WS_BASE_URL: "wss://api.steptothefuture.it",
    // WS_OPTIONS: {
    //     path: '/live',
    //     transports: ['websocket'],
    //     reconnection: true,
    //     reconnectionDelay: 800,
    //     reconnectionDelayMax: 3000,
    //     reconnectionAttempts: Infinity,
    // }


};

export default Constants;
