import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Constants from '../helpers/constants';
import axiosHelper from '../helpers/axiosHelper';
// import { setInterestsStats } from '../../../store/StepStore';
import './faprofileresult.scss';
import { Visit } from '../models/Visit';
import { InterestStat } from '../models/InterestStat';

interface FAProfileProps {
  visit: Visit;
  showDetails: boolean;
  showOthersInterests: boolean;
  showClaim: boolean;
}

const FAProfileChartComponent: React.FC<FAProfileProps> = ({ visit, showDetails, showOthersInterests, showClaim }) => {
  const { t } = useTranslation();
  const [cdValue, setCdValue] = useState(0);
  const [pfValue, setPfValue] = useState(0);
  const [faValue, setFaValue] = useState(0);
  const [percSameInterests, setPercSameInterests] = useState(0);
  
  const [interestsStats, setInterestsStats]=useState<InterestStat[]>([]);

  useEffect(() => {
    if (visit?.scoreCD) {
      setCdValue(Math.floor((visit?.scoreCD * 100) / Constants.MAX_CD_SCORE));
    }
    if (visit?.scorePF) {
      setPfValue(Math.floor((visit?.scorePF * 100) / Constants.MAX_PF_SCORE));
    }
    // console.log('score CD = ' + cdValue);
    // console.log('score PF = ' + pfValue);
    // console.log('visit score CD = ' + visit?.scoreCD);
    // console.log('visit score PF = ' + visit?.scorePF);
    axiosHelper.getInterestsStats().then((response) => {
      setInterestsStats(response.data);
    });

    setFaValue((cdValue + pfValue) / 2);

    return () => { };
  }, [visit?.scoreCD, visit?.scorePF, cdValue, pfValue]);

  useEffect(() => {
    if (visit?.interests != null) {
      var totalInterests = 0;
      var totalMyInterest = 0;
    
      interestsStats.forEach((interestStat) => {
        totalInterests += interestStat.total;
        if (interestStat.interests === visit?.interests) {
          totalMyInterest = interestStat.total;
        }
      });
      setPercSameInterests(Math.floor((totalMyInterest * 100) / totalInterests));
    }
    return () => { };
  }, [visit?.interests, interestsStats, percSameInterests]);

  return (
    <div className={`faProfileCharts TOP`}>
      <div className='faProfileChartsTitle'>{t('YF_TITLE')}</div>
      <div className='faProfileDonutChart'>
        <div className='chart'>
          <div className='donut donutFA' style={{ background: `conic-gradient(#3b3b3c 0% ${faValue}%, #fdc400 ${faValue}% 100%)` }}></div>
          <div className='donut donutCD' style={{ background: `conic-gradient(#5a606e 0% ${cdValue}%, #fdc400 ${cdValue}% 100%)` }}></div>
          <div className='donut donutPF' style={{ background: `conic-gradient(#dadada 0% ${pfValue}%, #fdc400 ${pfValue}% 100%)` }}></div>
          <div className='donut donutCenter'>
            {faValue}
            <span>%</span>
          </div>
        </div>

        <div className='legend'>
          <span className='dot'></span>
          {t('YF_DONUT_CHART_LEGEND')}
        </div>
      </div>
      {showDetails && (
        <div className='faProfileBarCharts'>
          <div className='faProfileBarChartTitle'>{t('YF_DIGITAL_SKILLS')}</div>
          <div className='faProfileBarChart faProfileBarChartCD'>
            <div className='bar'>
              <div className='solid-bar' style={{ width: `${cdValue}%` }}></div>
            </div>
            <div className='value'>{cdValue}%</div>
          </div>
          <div className='faProfileBarChartTitle'>{t('YF_INCLINATION_FOR_THE_FUTURE')}</div>
          <div className='faProfileBarChart faProfileBarChartPF'>
            <div className='bar'>
              <div className='solid-bar' style={{ width: `${pfValue}%` }}></div>
            </div>
            <div className='value'>{pfValue}%</div>
          </div>
        </div>
      )}
      {showOthersInterests && (
        <div className='faProfileOthersInterests'>
          {(visit?.interests != null) && <div className='faProfileInterestsTitle'>{t('YF_PEOPLE_SHARING_INTEREST')}</div>}
          <div className='faProfileInterestsValue'>
            {(visit?.interests != null) && (
              <>
                <img src='./assets/img/sfere.svg' alt='' />
                <h1>{percSameInterests}%</h1>
                <p>{t('INTERESTS_ANSWER_' + visit?.interests)}</p>
              </>
            )}
            {showClaim ? (
              <>
                <p className='faProfileInterestsBottomLine1'>{t('YF_PEOPLE_SHARING_INTEREST_BOTTOM_LINE1')}</p>
                <p className='faProfileInterestsBottomLine2'>{t('YF_PEOPLE_SHARING_INTEREST_BOTTOM_LINE2')}</p>
              </>
            ) : ""}
          </div>
        </div>
      )}
    </div>
  );
};

export default FAProfileChartComponent;
